import React, { useContext } from "react";
import { AppContext } from "../util/AppContext";
import { getConfigValue } from "../util/getConfigValue";
import Mustache from "mustache";
import ReactPaginate from 'react-paginate';

export function Pagination({ numberOfPages, currentPage, setCurrentPage }) {
    const [appState, setAppState] = useContext(AppContext);
    const messageTemplate = getConfigValue(appState.config.Common?.PaginationText, appState)?.Value;

    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };
    
    return (
        
        <nav aria-label="Page navigation">
            <div className="d-flex justify-content-center d-md-none">{messageTemplate && Mustache.render(messageTemplate, { currentPage, numberOfPages })}</div>
            <ReactPaginate
                breakLabel="..."
                nextLabel={<span aria-hidden="true">{getConfigValue(appState.config.Common?.Next, appState)?.Value}<span className="fa-regular fa-chevrons-right ms-2"></span></span>}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={numberOfPages}
                previousLabel={<span aria-hidden="true"><span className="fa-regular fa-chevrons-left me-2"></span>{getConfigValue(appState.config.Common?.Previous, appState)?.Value}</span>}
                renderOnZeroPageCount={null}
                forcePage={currentPage}
                className="pagination justify-content-center"
                pageClassName="page-item d-none d-md-block"
                pageLinkClassName="page-link"
                activeClassName="page-item active d-none d-md-block"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                disabledClassName="disabled"
                breakClassName="page-item"
                breakLinkClassName="page-link"
            />           
        </nav>
    )
}