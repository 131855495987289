import React, { useEffect, useContext, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../util/AppContext';
import useComponentVisible from '../hooks/useComponentVisible';
import DashboardFilterDropdown from '../components/dashboard/DashboardFilterDropdown';
import DashboardItemRow from '../components/dashboard/DashboardItemRow';
import { getConfigValue } from '../util/getConfigValue';
import { formatSellSheetDate } from '../util/prettyDate';
import { filterByValue } from '../util/filterByValue';
import PreviewModal from '../components/PreviewModal';
import ToastAlert from '../components/ToastAlert';
import { getFileNameFromHeader } from '../util/getFileNameFromHeader';
import { Pagination } from '../components/Pagination';
import Dropdown from 'react-bootstrap/Dropdown';
import { useErrorBoundary } from 'react-error-boundary';
import { trackDocumentRequest, trackingEventType } from '../API/documentGenerate';

function Dashboard() {
    const { showBoundary } = useErrorBoundary();
    const [appState, setAppState] = useContext(AppContext);
    const [sellSheets, setSellSheets] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [dashboardConfig, setDashboardConfig] = useState({});
    const [filterMenuRef, filterMenuIsVisible, setFilterMenuIsVisible] = useComponentVisible(false);
    const [itemMenuRef, itemMenuIsVisible, setItemMenuIsVisible] = useComponentVisible(false);
    const professionalPrintingEnabled = getConfigValue(appState?.config?.ProfessionalPrinting?.Enabled, appState)?.Value === 'true' || false;
    const downLoadEnabled = getConfigValue(appState.config?.Generate?.Document?.Download?.Enabled, appState)?.Value === 'true' || false;
    const printEnabled = getConfigValue(appState.config?.Generate?.Document?.Print?.Enabled, appState)?.Value === 'true' || false;
    const batchPrintEnabled = getConfigValue(appState.config?.BatchPrint?.Enabled, appState)?.Value === 'true' || false;

    const navigate = useNavigate();

    const [defaultFilterState, setDefaultFilterState] = useState({
        current: true,
        archive: true,
        activeHistory: 3,
        previousHistory: 0,
        submitted: false,
        delayed: false,
        processing: false
    });

    const [filterState, setFilterState] = useState(defaultFilterState);
    const [filteredSellSheets, setFilteredSellSheets] = useState([]);
    const [noResultsFound, setNoResultsFound] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [pdfFileName, setPdfFileName] = useState('');
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastTitle, setToastTitle] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [currentRecords, setCurrentRecords] = useState([]);
    const dateRegex = new RegExp(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/);
    const [quickSheetDownLoadEnabled, setQuickSheetDownLoadEnabled] = useState((getConfigValue(appState?.config?.QuickSheets?.Download?.Enabled, appState)?.Value || 'false') === 'true');
    const [systemDateFormat, setSystemDateFormat] = useState(getConfigValue(appState?.config?.System?.DateTime?.Format, appState)?.Value || null);

    function setFocus(focus) {
        setAppState({
            ...appState,
            ...({
                market: {},
                territory: {}
            }),
            focus: focus,
            prescriber: {},
            products: [],
            currentProduct: "",
            plans: [],
            category: "",
            mainMessage: "",
        });
    }

    useEffect(() => {
        if (appState.configLoaded && appState.configRefreshed) {
            setDashboardConfig(getConfigValue(appState.config.Dashboard, appState));
            setSystemDateFormat(getConfigValue(appState?.config?.System?.DateTime?.Format, appState)?.Value || null);
            setQuickSheetDownLoadEnabled((getConfigValue(appState?.config?.QuickSheets?.Download?.Enabled, appState)?.Value || 'false') === 'true');
            setDefaultFilterState({
                current: true,
                archive: true,
                activeHistory: parseInt(getConfigValue(appState?.config?.Dashboard?.Filter?.Months?.Default, appState)?.Value) || 3,
                previousHistory: 0,
                submitted: false,
                delayed: false,
                processing: false
            });
        }
    }, [appState, appState.configLoaded, appState.configRefreshed]);

    useEffect(() => {
        if (!dashboardConfig.SellSheetTable || dashboardConfig.SellSheetTable.Columns.length === 0) {
            return;
        }
    }, [dashboardConfig]);

    const getSellSheets = useCallback(function () {
        let url = `${process.env.REACT_APP_API_URL}SellSheet?maxMonths=${filterState.activeHistory}`;

        if (!filterState.activeHistory) {
            url = `${process.env.REACT_APP_API_URL}SellSheet`;
        }

        const fetchDashboard = async () => {
            setIsLoading(true);
            await fetch(url, {
                method: 'GET',
                credentials: 'include',
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    setSellSheets(responseJson.rows.map((sellSheet) => formatSellSheetDate(sellSheet, systemDateFormat)));
                    setHeaders(responseJson.headers);
                    setIsLoading(false);
                })
                .catch((err) => {
                    showBoundary(err);
                });
        };
        fetchDashboard();
    }, [filterState.activeHistory, systemDateFormat]);

    //Get dashboard sellSheets
    useEffect(() => {
        // only fetch if a user is logged in
        if (!appState.user && !appState.configLoaded) {
            return;
        }

        getSellSheets();
    }, [appState.user, appState.configLoaded, filterState.activeHistory, getSellSheets]);

    const filterSellSheet = useCallback(function (element) {
        let shouldInclude = false;
        if ((filterState.current === true && element.data.active === true) || (filterState.archive === true && element.data.active === false)) {
            shouldInclude = true;

            if ((filterState.submitted === true || filterState.processing === true || filterState.delayed === true) && element.data.printJobStatus === null) {
                // if the print job status is null and we have opted for a filter it cannot be included.
                shouldInclude = false;
            }
            else if (filterState.submitted === true || filterState.processing === true || filterState.delayed === true) {
                // print job status is not null and one or more statuses are selected
                shouldInclude = (filterState.submitted === true && element.data.printJobStatus === 2)
                    || (filterState.processing === true && (element.data.printJobStatus === 0 || element.data.printJobStatus === 1))
                    || (filterState.delayed === true && element.data.printJobStatus === -1);
            }
        }
        return shouldInclude;
    }, [filterState]);

    useEffect(() => {
        if (!appState.configLoaded) {
            return;
        }

        //first apply the filters to reduce the search space, then apply the search term if defined
        var results = sellSheets.filter(filterSellSheet);
        if (searchInput.length > 0) {
            results = filterByValue(results, searchInput.trim(), [], true);
        }
        setFilteredSellSheets(results);
        if (!results.length) {
            setNoResultsFound(true);
        } else {
            setNoResultsFound(false);
        }
        const pageSize = parseInt(dashboardConfig.RecordsPerPage?.Value, 10);
        var pages = Math.floor(results.length / pageSize);
        pages = results.length % pageSize > 0 ? pages + 1 : pages;
        setNumberOfPages(pages);
    }, [sellSheets, filterState, searchInput, currentPage, dashboardConfig, appState.configLoaded, filterSellSheet]);

    useEffect(() => {
        const pageSize = parseInt(dashboardConfig.RecordsPerPage?.Value, 10);
        const indexOfLastRecord = (currentPage+1) * pageSize;
        const indexOfFirstRecord = indexOfLastRecord - pageSize;
        const currentRecords = filteredSellSheets.slice(indexOfFirstRecord, indexOfLastRecord);
        setCurrentRecords(currentRecords);
    }, [filteredSellSheets, currentPage]);

    useEffect(() => {
        if (isLoading) {
            setFeedbackMessage(getConfigValue(appState.config.Common?.Loading, appState)?.Value);
        } else if (noResultsFound) {
            setFeedbackMessage(getConfigValue(appState.config.Common?.NoResultsFound, appState)?.Value);
        } else {
            setFeedbackMessage('');
        }
    }, [isLoading, noResultsFound, appState]);

    function handleChange(event) {
        setCurrentPage(0);
        setSearchInput(event.target.value);
    }

    function handleFilterChange(newFilter) {
        setCurrentPage(0);
        setFilterState(newFilter);
    }

    async function onGetPDF(id, documentGenerationProperties) {
        if (!id) {
            return;
        }

        let pdfFileName = '';
        let pdfUrl = '';

        await fetch(`${process.env.REACT_APP_API_URL}SellSheet/Download/${id}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((response) => {
                if (response.status !== 200) {
                    throw response.statusText;
                }

                let fileName = getFileNameFromHeader(response);
                pdfFileName = fileName;

                // store the properties of the last document generated
                setAppState({
                    ...appState,
                    lastGeneratedDocument: documentGenerationProperties,
                    sellSheetId: id,
                });

                return response.blob();
            })
            .then((blob) => {
                setIsLoading(false);
                const url = URL.createObjectURL(blob);
                pdfUrl = url;
            })
            .catch((err) => {
                setShowPreviewModal(false);
                setIsLoading(false);
                showBoundary(err);
            });

        return { pdfFileName, pdfUrl };
    }

    async function onAccessClick(id, documentGenerationProperties) {
        if (!id) {
            return;
        }

        setIsLoading(true);
        let onGetPDFResult = await onGetPDF(id, documentGenerationProperties);

        if (onGetPDFResult?.pdfUrl) {
            setPdfFileName(onGetPDFResult.pdfFileName);
            setPdfUrl(onGetPDFResult.pdfUrl);
            setShowPreviewModal(true);
        }
    }

    async function onPrintClick(id, documentGenerationProperties) {
        if (!id) {
            return;
        }

        setIsLoading(true);
        let onGetPDFResult = await onGetPDF(id, documentGenerationProperties);

        if (onGetPDFResult?.pdfUrl) {
            try {
                // track print request
                trackDocumentRequest(trackingEventType.Print, appState.lastGeneratedDocument, appState.sellSheetId);

                window.open(onGetPDFResult.pdfUrl, 'PRINT');
            } catch (e) {
                showBoundary(e);
            }
        }
    }

    async function onDownloadClick(id, documentGenerationProperties) {
        if (!id) {
            return;
        }

        setIsLoading(true);
        let onGetPDFResult = await onGetPDF(id, documentGenerationProperties);

        if (onGetPDFResult?.pdfUrl) {
            try {
                // track print request
                trackDocumentRequest(trackingEventType.Print, appState.lastGeneratedDocument, appState.sellSheetId);

                const link = document.createElement('a');
                link.href = onGetPDFResult.pdfUrl;
                link.download = onGetPDFResult.pdfFileName;
                link.click();
            } catch (e) {
                showBoundary(e);
            }
        }
    }

    async function onCreateProfessionalPrintingDocumentClick(id, documentGenerationProperties) {
        if (!id) {
            return;
        }

        setIsLoading(true);
        let onGetPDFResult = await onGetPDF(id, documentGenerationProperties);

        if (onGetPDFResult?.pdfUrl) {
            try {
                navigate('/createProfessionalPrintingDocument', { state: { pdfURL: onGetPDFResult.pdfFileName, fileName: onGetPDFResult.pdfFileName } });
            } catch (e) {
                showBoundary(e);
            }
        }
    }

    function pageChange(pageNumber) {
        setCurrentPage(pageNumber);
    }

    return (
        <div>
            <div className='container-xl'>
                <div className='row'>
                    <div className='col-12'>
                        <h1 className='type-24 mt-4 color-grey-d1'>{dashboardConfig.Header?.Value}</h1>
                    </div>
                </div>
            </div>
            <div className='container-xl mt-3 mt-md-4'>
                <div className='row my-3 mb-lg-4'>
                    <div className="col-12 mb-3 mb-md-0 col-md">
                        <div className='input-group'>
                            <input
                                value={searchInput}
                                type='text'
                                className='form-control icon-search'
                                id='dashboard-search'
                                placeholder='Search'
                                onChange={handleChange}></input>
                            <button className='btn btn-outline-primary btn-icon-only ms-0'>
                                <span className='fa-regular fa-magnifying-glass btn-icon'></span>
                            </button>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3 ps-md-0 d-flex">
                        <div className="dropdown flex-fill d-flex">
                            <button
                                type='button'
                                className='btn btn-outline-primary btn-icon w-100 me-2'
                                onClick={() => setFilterMenuIsVisible(!filterMenuIsVisible)}
                                data-bs-toggle='collapse'
                                data-bs-target='#options-row-1'
                                aria-expanded='false'
                                aria-controls='options-row-1'>
                                {dashboardConfig.FiltersButton?.Value}
                                <span className='fa-regular fa-chevron-down btn-icon'></span>
                            </button>
                            {filterMenuIsVisible && (
                                <DashboardFilterDropdown setFilterState={handleFilterChange} filterState={filterState} defaultFilterState={defaultFilterState} />
                            )}
                        </div>
                        <Dropdown className='dropdown-menu-end'>
                            <Dropdown.Toggle className='btn btn-primary btn-icon-only'>
                                <span className='fa-regular fa-plus btn-icon'></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {batchPrintEnabled &&
                                    <Dropdown.Item>
                                        <Link onClick={() => setFocus('HCPs')} to='/FocusPage' className='dropdown-item'>
                                            {getConfigValue(appState?.config?.NavBar?.Menu?.CreateSalesAid?.Label, appState)?.Value || null}
                                        </Link>
                                    </Dropdown.Item>}
                                {!batchPrintEnabled &&
                                    <Dropdown.Item>
                                        <Link onClick={() => setFocus('HCPs')} to='/TerritoryPage' className='dropdown-item'>
                                            {getConfigValue(appState?.config?.NavBar?.Menu?.CreateSalesAid?.Label, appState)?.Value || null}
                                        </Link>
                                    </Dropdown.Item>}
                                {quickSheetDownLoadEnabled &&
                                    <Dropdown.Item>
                                        <Link onClick={() => setFocus('HCPs')} to='/QuickSheetsPage' className='dropdown-item'>
                                            {getConfigValue(appState?.config?.NavBar?.Menu?.DownloadQuickSheet?.Label, appState)?.Value || null}
                                        </Link>
                                    </Dropdown.Item>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <hr className='my-4 d-lg-none'></hr>
                <div className='container-xl'>
                    {!feedbackMessage &&
                        currentRecords.map((sellSheet) => {
                            return <DashboardItemRow
                                key={sellSheet.data.id}
                                headers={headers}
                                sellSheet={sellSheet}
                                onAccessClick={onAccessClick}
                                onPrintClick={onPrintClick}
                                onDownloadClick={onDownloadClick}
                                onCreateProfessionalPrintingDocumentClick={onCreateProfessionalPrintingDocumentClick}
                                canShowStatus={true}
                                professionalPrintingEnabled={professionalPrintingEnabled}
                                downLoadEnabled={downLoadEnabled}
                                printEnabled={printEnabled} />;
                        })}

                    {feedbackMessage ? (
                        <div className='row pd-plan-table-row pd-plan-table-card align-items-center'>
                            <div className='color-grey-d1 py-4'>{feedbackMessage}</div>
                        </div>
                    ) : null}
                </div>
            </div>
            {numberOfPages > 1 && <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={pageChange} />}

            <PreviewModal
                show={showPreviewModal}
                setShow={setShowPreviewModal}
                pdfUrl={pdfUrl}
                isLoading={isLoading}
                showGenerateBack={false}
                showPrintDownload={true}
                onGenerateClick={null}
                isValid={true}
                pdfFileName={pdfFileName}
                supportsProfessionalPrinting={true}
            />

            <ToastAlert show={showToast} setShow={setShowToast} title={toastTitle} message={toastMessage} />
        </div>
    );
}

export default Dashboard;
